import { ChangeEvent, useEffect, useState } from "react";
import {
  Container,
  Paper,
  TextField,
  Typography,
  Box,
  IconButton,
  InputAdornment,
} from "@mui/material";
import LoginIcon from "@mui/icons-material/Login";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import saveTheDateImage from "./../assets/savethedate-v2.jpg";
import axios from "axios";
import { LOCALSTORAGE_PASSWORD_KEY } from "../config";
import { Footer } from "../components/Footer";
import { useLocation, useNavigate } from "react-router-dom";
import LoadingScreen from "../components/LoadingScreen";

interface LoginProps {
  setIsLoggedIn: React.Dispatch<React.SetStateAction<boolean>>;
  password: string;
  setPassword: React.Dispatch<React.SetStateAction<string>>;
  setShowImpressum: React.Dispatch<React.SetStateAction<boolean>>;
}

const Login = ({
  setIsLoggedIn,
  password,
  setPassword,
  setShowImpressum,
}: LoginProps) => {
  const [error, setError] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showLoginScreen, setShowLoginScreen] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  const handlePasswordChange = (event: ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
    setError("");
  };

  const loginUser = async () => {
    console.debug(`loginUser: ${password}`);
    if (password.length === 0) {
      return;
    }
    const timeout = setTimeout(async () => {
      let windowUrl = window.location.origin.includes('localhost') ? "https://zimmler-doll.de/" : window.location.origin
      await axios
        .get(`${windowUrl}/cgi-bin/login.py?password=${password}`)
        .then((response) => {
          if (response.data.status === "success") {
            localStorage.setItem(LOCALSTORAGE_PASSWORD_KEY, password);

            setIsLoggedIn(true);
          } else {
            setError("Login Fehler. Überprüfe Dein Passwort.");
          }
        })
        .catch((error) => {
          console.log("error");
          setError("Server error. Versuche es erneut!");
          console.error(error);
        });
    }, 1000);
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const extractedPassword = searchParams.get("password");

    const loginProcess = async () => {
      if (extractedPassword != null) {
        localStorage.setItem(LOCALSTORAGE_PASSWORD_KEY, extractedPassword)
      }
      if (extractedPassword != null && localStorage.getItem(LOCALSTORAGE_PASSWORD_KEY) != extractedPassword) {
        localStorage.removeItem(LOCALSTORAGE_PASSWORD_KEY)
        console.debug("prefill password via URL");
        setPassword(extractedPassword);
        setError("Das Passwort ist vorausgefüllt...");
        setShowPassword(true);
        searchParams.delete("password");
        await loginUser();
        navigate({ search: searchParams.toString() });
      } else if (localStorage.getItem(LOCALSTORAGE_PASSWORD_KEY)) {
        console.debug(`login from local storage`);
        await loginUser();
        if (extractedPassword != null) {
          searchParams.delete("password");
          navigate({ search: searchParams.toString() });
        }
      } else if (extractedPassword) {
        console.debug("prefill password via URL");
        setPassword(extractedPassword);
        setError("Das Passwort ist vorausgefüllt...");
        setShowPassword(true);
        searchParams.delete("password");
        await loginUser();
        navigate({ search: searchParams.toString() });
      } else {
        console.debug("no password extracted");
        setShowLoginScreen(true);
      }
      const timeout = setTimeout(() => {
        setShowLoginScreen(true);
      }, 2000);
    };

    loginProcess();
    // eslint-disable-next-line
  }, []);

  return (
    <Box>
      {showLoginScreen ? (
        <>
          <Box
            id="background-image"
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              position: "relative",
              width: "100%",
              height: "100vh",
              "@media (min-width: 961px)": {
                height: "100vh",
              },
              overflow: "hidden",
              backgroundSize: "cover",
              backgroundPosition: "10% 45%",
              "@media (max-width: 600px)": {
                backgroundPosition: "25% 60%",
              },
              backgroundImage: `linear-gradient(to bottom, rgba(40, 40, 40, 0.4), rgba(114, 114, 114, 0.52)), url(${saveTheDateImage})`,
            }}
          >
            <Container
              maxWidth="xs"
              sx={{
                position: "absolute",
                "@media (min-width: 961px)": {
                  top: "0",
                  marginTop: "5%",
                },
                "@media (max-width: 961px)": {
                  bottom: "0",
                  marginBottom: "15vh",
                },
                left: "50%",
                transform: "translateX(-50%)",
              }}
            >
              <Paper style={{ padding: "20px", textAlign: "center" }}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      width: "100%",
                    }}
                  >
                    <TextField
                      label="Passwort"
                      variant="outlined"
                      type={showPassword ? "text" : "password"}
                      value={password}
                      onChange={handlePasswordChange}
                      onKeyDown={(event) => {
                        if (event.key === "Enter") {
                          loginUser();
                        }
                      }}
                      fullWidth
                      InputProps={{
                        onClick: (
                          event: React.MouseEvent<HTMLDivElement, MouseEvent>
                        ) => {
                          event.preventDefault();
                        },
                        startAdornment: (
                          <InputAdornment position="start">
                            <IconButton
                              onClick={() => {
                                setShowPassword(!showPassword);
                              }}
                            >
                              {showPassword ? (
                                <VisibilityOffIcon sx={{ color: "grey" }} />
                              ) : (
                                <VisibilityIcon sx={{ color: "grey" }} />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      sx={{
                        width: "90%",
                        fontSize: showPassword ? 16 : 120,
                        "& .MuiOutlinedInput-root": {
                          "& input": {
                            fontFamily: showPassword ? "inherit" : "Arial",
                            color: showPassword ? "inherit" : "grey",
                          },
                        },
                      }}
                    />
                    <IconButton
                      onClick={loginUser}
                      sx={{
                        width: "10%",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <LoginIcon sx={{ color: "grey" }} />
                    </IconButton>
                  </Box>
                  <Box
                    sx={{
                      marginTop: "5%",
                    }}
                  >
                    <Typography>Das Passwort findest Du auf der Save-The-Date-Karte und der Einladung</Typography>
                  </Box>
                  {error.length > 0 && (
                    <Box
                      sx={{
                        marginTop: "5%",
                      }}
                    >
                      <Typography>{error}</Typography>
                    </Box>
                  )}
                </Box>
              </Paper>
            </Container>
          </Box>
          <Footer setShowImpressum={setShowImpressum} />
        </>
      ) : (
        <LoadingScreen></LoadingScreen>
      )}
    </Box>
  );
};

export default Login;
