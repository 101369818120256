import { useEffect, useRef } from "react";
import {
  Box,
  CardContent,
  Chip,
  Container,
  CssBaseline,
  Divider,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { NamePageBreak } from "../components/NamePageBreak";
import saveTheDateImage from "./../assets/savethedate-v2.jpg";
import dropboxCodeImage from "./../assets/dropbox_dateianfrage_qrcode.svg";
import DetailCard from "../components/DetailCard";
import { Footer } from "../components/Footer";
import Trauzeuge from "../components/DetailCard/Trauzeuge";
import anreisePlan from "./../assets/anreise_plan.png";
import franziMarianGif from "./../assets/franziMariGif.mp4";

import avatarDanielJaeck from "./../assets/trauzeugen/daniel_jaeck.jpg";
import avatarFlorianBentele from "./../assets/trauzeugen/florian_bentele.jpg";
import avatarChristinaOesterlein from "./../assets/trauzeugen/christina_oesterlein.jpg";
import avatarMariaSchoepp from "./../assets/trauzeugen/maria_schoepp.jpg";
import ImageCarousel from "../components/DetailCard/ImageCarousel";
import Countdown from "../components/Countdown";
import MusicInput from "../components/MusicInput";
import { FONT_WEIGHT_TEXT } from "../config";
import RegistrationForm from "../components/RegistrationForm";
import AgendaTimeline from "../components/DetailCard/AgendaTimeline";
import DirectionsCarFilledOutlinedIcon from "@mui/icons-material/DirectionsCarFilledOutlined";
import HotelOutlinedIcon from "@mui/icons-material/HotelOutlined";
import CheckroomIcon from "@mui/icons-material/Checkroom";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import RedeemIcon from "@mui/icons-material/Redeem";
import AirportShuttleOutlinedIcon from "@mui/icons-material/AirportShuttleOutlined";
import PianoOutlinedIcon from "@mui/icons-material/PianoOutlined";
import { shouldHideElement } from "../utils";

interface HomePageProps {
  setShowImpressum: React.Dispatch<React.SetStateAction<boolean>>;
}

const _MARGIN_TOP_TEXT = "10px";
const _TS_TO_REMOVE_MUSIC_DETAIL_CARD = new Date(
  "2024-08-17T00:00:00Z"
).getTime();

const HomePage = ({ setShowImpressum }: HomePageProps) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const videoRef = useRef<HTMLVideoElement>(null);

  useEffect(() => {}, []);

  useEffect(() => {
    const scrollToBottom = () => {
      window.scrollTo({
        top: 100,
        behavior: "smooth",
      });
    };

    scrollToBottom();

    const scrollBackToTop = setTimeout(() => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }, 500);

    if (videoRef.current) {
      videoRef.current.playbackRate = 0.35; // Setzt die Wiedergabegeschwindigkeit auf 50%
    }

    return () => {
      clearTimeout(scrollBackToTop);
    };
  });

  return (
    <>
      <Box sx={{ textAlign: "justify" }}>
        <Box
          id="background-image"
          sx={{
            display: "flex",
            flexDirection: "column",
            position: "relative",
            width: "100vw",
            height: "90vh",
            "@media (min-width: 961px)": {
              height: "100vh",
            },
            overflow: "hidden",
            backgroundSize: "cover",
            backgroundPosition: "10% 45%",
            "@media (max-width: 600px)": {
              backgroundPosition: "25% 60%",
            },
            backgroundImage: `linear-gradient(to bottom, rgba(40, 40, 40, 0.4), rgba(114, 114, 114, 0.52)), url(${saveTheDateImage})`,
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignSelf: "center",
              justifySelf: "center",
              marginTop: "1%",
              flexDirection: "column",
              color: "white",
              textShadow: "1px 1px 4px rgba(0, 0, 0, 0.5)",
            }}
          >
            <Box
              sx={{
                fontFamily: "SignatureCollection",
                "@media (max-width: 600px)": {
                  fontSize: "6em",
                },
                "@media (min-width: 601px) and (max-width: 960px)": {
                  fontSize: "8em",
                },
                "@media (min-width: 961px)": {
                  fontSize: "10em",
                },
              }}
            >
              Dankeschön!
            </Box>
          </Box>
          <Box
            id="date"
            sx={{
              position: "absolute",
              bottom: "20px",
              alignSelf: "center",
              justifySelf: "center",
              marginTop: "1%",
              flexDirection: "column",
              color: "white",
              textShadow: "1px 1px 4px rgba(0, 0, 0, 0.5)",
              marginBottom: "2.5%", // Set a margin of 2.5% to the bottom
              "@media (max-width: 600px)": {
                fontSize: "2em",
              },
              "@media (min-width: 601px) and (max-width: 960px)": {
                fontSize: "2.5em",
              },
              "@media (min-width: 961px)": {
                fontSize: "3.5em",
              },
            }}
          >
            17. August 2024
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginTop: "30px",
          }}
        >
          <NamePageBreak />
          <Box
            sx={{
              width: "15%",
              marginTop: "10px",
              marginBottom: "10px",
              backgroundColor: "black",
            }}
          >
            <Divider />
          </Box>
          <Box
            sx={{
              marginBottom: "20px",
            }}
          >
            {/* <Countdown /> */}
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              flexWrap: "wrap",
              width: "90%",
            }}
          >
            <DetailCard
              title="DANKESCHÖN"
              showInfo={false}
              infoText=""
              isInitiallyFlipped={true}
            >
              <CardContent
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <video
                  ref={videoRef} // Referenz auf das Video-Element
                  src={franziMarianGif} // Ersetze dies durch den Pfad zu deinem MP4-Video
                  autoPlay
                  loop
                  muted
                  playsInline
                  style={{ width: "100%", height: "auto", maxWidth: "300px" }}
                >
                  Dein Browser unterstützt dieses Video-Format nicht.
                </video>
                <Typography
                  variant="body1"
                  color="textSecondary"
                  style={{ marginTop: "10px", textAlign: "center" }}
                >
                  Dankeschön, dass Ihr mit uns gefeiert habt. Unsere Hochzeit
                  hätte nicht schöner sein können. Wir freuen uns schon darauf,
                  Bilder mit Euch zu teilen und in Erinnerungen zu schwelgen.
                </Typography>
              </CardContent>
            </DetailCard>
            <DetailCard
              title="GEFUNDEN ODER VERLOREN?"
              showInfo={false}
              infoText=""
              isInitiallyFlipped={true}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Typography sx={{ fontWeight: FONT_WEIGHT_TEXT }}>
                  Falls Ihr an unserer Hochzeit etwas verloren habt, oder Euch
                  am nächsten Tag aufgefallen sein sollte, dass Ihr mit einem
                  zusätzlichen Paar Socken nach Hause gekommen seid, dann meldet
                  Euch gerne direkt bei uns.
                </Typography>
                <Typography sx={{ marginTop: _MARGIN_TOP_TEXT }}>
                  Aktuell auf der Liste wären:
                  <ul style={{ listStyleType: "none", padding: 0 }}>
                    <li>- Brille (wanted)</li>
                    <li>- Regenschirme (wanted)</li>
                  </ul>
                </Typography>
                <Typography>Vielen Dank an Euch alle!</Typography>
              </Box>
            </DetailCard>
            <DetailCard title="BILDER" showInfo={false} infoText="">
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Typography sx={{ fontWeight: FONT_WEIGHT_TEXT }}>
                    Hier werdet Ihr später einen (passwortgeschützten) Link zu
                    den Fotos finden.
                  </Typography>
                  <Typography>
                    Für alle spontanen und geplanten Schnappschüsse, die Ihr
                    während der Hochzeit gemacht habt, würden wir uns freuen,
                    wenn Ihr diese hochladen könntet. Klickt hierzu einfach auf
                    den Link oder scanned den QR-Code. VIELEN DANK 🙂
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      marginTop: _MARGIN_TOP_TEXT,
                    }}
                  >
                    <Typography
                      sx={{
                        marginRight: "16px", // Space before the divider
                      }}
                    >
                      <a
                        style={{ color: "inherit" }}
                        target="_blank"
                        rel="noreferrer"
                        href="https://www.dropbox.com/request/J7IhqNVJ8Cz6YY6b2R0T"
                      >
                        Link zur Dropbox
                      </a>
                    </Typography>
                    <Divider
                      orientation="vertical"
                      flexItem
                      sx={{
                        height: "50px", // Custom height for the divider
                        marginX: "16px", // Space around the divider
                        alignSelf: "center", // Center the divider vertically
                      }}
                    />
                    <Box
                      component="img"
                      alt="QR Code"
                      src={dropboxCodeImage}
                      sx={{
                        width: "auto",
                        maxHeight: "100px",
                        filter:
                          "invert(55%) sepia(10%) saturate(250%) hue-rotate(15deg) brightness(90%) contrast(85%)",
                        //filter: "invert(75%) sepia(12%) saturate(200%) hue-rotate(10deg) brightness(95%) contrast(85%)"
                      }}
                    />
                  </Box>
                  <Typography sx={{ marginTop: _MARGIN_TOP_TEXT }}>
                    Um Euch schonmal einen kleinen Vorgeschmack zu geben, findet
                    Ihr hier bereits ein paar Schnappschüsse der letzten Jahre:
                  </Typography>
                </Box>
                <Box
                  sx={{
                    marginTop: _MARGIN_TOP_TEXT,
                    display: "flex",
                    alignItems: "center", // Center items horizontally
                    justifyContent: "center",
                  }}
                >
                  <ImageCarousel></ImageCarousel>
                </Box>
              </Box>
            </DetailCard>
          </Box>
        </Box>
        <Footer setShowImpressum={setShowImpressum} />
      </Box>
    </>
  );
};

export default HomePage;
