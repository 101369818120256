import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MobileStepper from "@mui/material/MobileStepper";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import SwipeableViews from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";

import img_2009 from "../../../assets/imageCarousel/2009.jpg";
import img_2010 from "../../../assets/imageCarousel/2010.jpg";
import img_2011 from "../../../assets/imageCarousel/2011.jpg";
import img_2012 from "../../../assets/imageCarousel/2012.jpg";
import img_2013 from "../../../assets/imageCarousel/2013.jpg";
import img_2013_1 from "../../../assets/imageCarousel/2013_1.jpg";
import img_2013_2 from "../../../assets/imageCarousel/2013_2.jpg";
import img_2014 from "../../../assets/imageCarousel/2014.jpg";
import img_2015 from "../../../assets/imageCarousel/2015.jpg";
import img_2016 from "../../../assets/imageCarousel/2016.jpg";
import img_2017 from "../../../assets/imageCarousel/2017.jpg";
import img_2018_1 from "../../../assets/imageCarousel/2018_1.jpg";
import img_2018_2 from "../../../assets/imageCarousel/2018_2.jpg";
import img_2019_1 from "../../../assets/imageCarousel/2019_1.jpg";
import img_2019_2 from "../../../assets/imageCarousel/2019_2.jpg";
import img_2020_1 from "../../../assets/imageCarousel/2020_1.jpg";
import img_2020_2 from "../../../assets/imageCarousel/2020_2.jpg";
import img_2021 from "../../../assets/imageCarousel/2021.jpg";
import img_2022_1 from "../../../assets/imageCarousel/2022_1.jpg";
import img_2022_2 from "../../../assets/imageCarousel/2022_2.jpg";
import img_2022_3 from "../../../assets/imageCarousel/2022_3.jpg";
import img_2022_4 from "../../../assets/imageCarousel/2022_4.jpg";
import img_2023_1 from "../../../assets/imageCarousel/2023_1.jpg";
import img_2023_2 from "../../../assets/imageCarousel/2023_2.jpg";
import img_2023_3 from "../../../assets/imageCarousel/2023_3.jpg";
import img_2023_4 from "../../../assets/imageCarousel/2023_4.jpg";
import img_2023_5 from "../../../assets/imageCarousel/2023_5.jpg";
import img_2024_6 from "../../../assets/imageCarousel/2023_6.jpg";
import { useState } from "react";

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const _FLIP_INTERVAL_SEC = 60000;

const images = [
  {
    label: "2009",
    imgPath: img_2009,
    backgroundPosition: "center center",
    backgroundPositionResponsive: "center center",
    backgroundSize: "contain",
    backgroundSizeResponsive: "auto 85%",
  },
  {
    label: "2010",
    imgPath: img_2010,
    backgroundPosition: "center top",
    backgroundPositionResponsive: "center center",
    backgroundSize: "contain",
    backgroundSizeResponsive: "auto 100%",
  },
  {
    label: "2011",
    imgPath: img_2011,
    backgroundPosition: "center bottom",
    backgroundPositionResponsive: "center bottom",
    backgroundSize: "cover",
    backgroundSizeResponsive: "auto 110%",
  },
  {
    label: "2012",
    imgPath: img_2012,
    backgroundPosition: "center center",
    backgroundPositionResponsive: "",
    backgroundSize: "contain",
    backgroundSizeResponsive: "",
  },
  {
    label: "2013",
    imgPath: img_2013,
    backgroundPosition: "center center",
    backgroundPositionResponsive: "",
    backgroundSize: "contain",
    backgroundSizeResponsive: "auto 100%",
  },
  {
    label: "2013",
    imgPath: img_2013_1,
    backgroundPosition: "center center",
    backgroundPositionResponsive: "center center",
    backgroundSize: "contain",
    backgroundSizeResponsive: "auto 120%",
  },
  {
    label: "2013",
    imgPath: img_2013_2,
    backgroundPosition: "center center",
    backgroundPositionResponsive: "",
    backgroundSize: "contain",
    backgroundSizeResponsive: "",
  },
  {
    label: "2014",
    imgPath: img_2014,
    backgroundPosition: "center center",
    backgroundPositionResponsive: "",
    backgroundSize: "cover",
    backgroundSizeResponsive: "",
  },
  {
    label: "2015",
    imgPath: img_2015,
    backgroundPosition: "center center",
    backgroundPositionResponsive: "center right",
    backgroundSize: "cover",
    backgroundSizeResponsive: "",
  },
  {
    label: "2016",
    imgPath: img_2016,
    backgroundPosition: "center bottom",
    backgroundPositionResponsive: "center right",
    backgroundSize: "cover",
    backgroundSizeResponsive: "",
  },
  {
    label: "2017",
    imgPath: img_2017,
    backgroundPosition: "center center",
    backgroundPositionResponsive: "",
    backgroundSize: "contain",
    backgroundSizeResponsive: "",
  },
  {
    label: "2018",
    imgPath: img_2018_1,
    backgroundPosition: "right bottom",
    backgroundPositionResponsive: "center bottom",
    backgroundSize: "120% 130%",
    backgroundSizeResponsive: "auto 120%",
  },
  {
    label: "2018",
    imgPath: img_2018_2,
    backgroundPosition: "center center",
    backgroundPositionResponsive: "",
    backgroundSize: "auto 150%",
    backgroundSizeResponsive: "",
  },
  {
    label: "2019",
    imgPath: img_2019_1,
    backgroundPosition: "center center",
    backgroundPositionResponsive: "",
    backgroundSize: "auto 140%",
    backgroundSizeResponsive: "",
  },
  {
    label: "2019",
    imgPath: img_2019_2,
    backgroundPosition: "center bottom",
    backgroundPositionResponsive: "",
    backgroundSize: "auto 130%",
    backgroundSizeResponsive: "",
  },
  {
    label: "2020",
    imgPath: img_2020_1,
    backgroundPosition: "left 70%",
    backgroundPositionResponsive: "left center",
    backgroundSize: "auto 132%",
    backgroundSizeResponsive: "cover",
  },
  {
    label: "2020",
    imgPath: img_2020_2,
    backgroundPosition: "center 85%",
    backgroundPositionResponsive: "center center",
    backgroundSize: "cover",
    backgroundSizeResponsive: "auto 90%",
  },
  {
    label: "2021",
    imgPath: img_2021,
    backgroundPosition: "center 60%",
    backgroundPositionResponsive: "",
    backgroundSize: "cover",
    backgroundSizeResponsive: "",
  },
  {
    label: "2022",
    imgPath: img_2022_1,
    backgroundPosition: "center center",
    backgroundPositionResponsive: "",
    backgroundSize: "cover",
    backgroundSizeResponsive: "",
  },
  {
    label: "2022",
    imgPath: img_2022_2,
    backgroundPosition: "center center",
    backgroundPositionResponsive: "",
    backgroundSize: "cover",
    backgroundSizeResponsive: "",
  },
  {
    label: "2022",
    imgPath: img_2022_3,
    backgroundPosition: "center bottom",
    backgroundPositionResponsive: "",
    backgroundSize: "auto 110%",
    backgroundSizeResponsive: "",
  },
  {
    label: "2022",
    imgPath: img_2022_4,
    backgroundPosition: "center 60%",
    backgroundPositionResponsive: "",
    backgroundSize: "auto 150%",
    backgroundSizeResponsive: "",
  },
  {
    label: "2023",
    imgPath: img_2023_1,
    backgroundPosition: "center center",
    backgroundPositionResponsive: "",
    backgroundSize: "cover",
    backgroundSizeResponsive: "",
  },
  {
    label: "2023",
    imgPath: img_2023_2,
    backgroundPosition: "center center",
    backgroundPositionResponsive: "",
    backgroundSize: "cover",
    backgroundSizeResponsive: "",
  },
  {
    label: "2023",
    imgPath: img_2023_3,
    backgroundPosition: "center bottom",
    backgroundPositionResponsive: "",
    backgroundSize: "cover",
    backgroundSizeResponsive: "",
  },
  {
    label: "2023",
    imgPath: img_2023_4,
    backgroundPosition: "center top",
    backgroundPositionResponsive: "",
    backgroundSize: "cover",
    backgroundSizeResponsive: "",
  },
  {
    label: "2023",
    imgPath: img_2023_5,
    backgroundPosition: "center center",
    backgroundPositionResponsive: "",
    backgroundSize: "auto 140%",
    backgroundSizeResponsive: "",
  },
  {
    label: "2023",
    imgPath: img_2024_6,
    backgroundPosition: "center 50%",
    backgroundPositionResponsive: "",
    backgroundSize: "auto 150%",
    backgroundSizeResponsive: "",
  },
];

function ImageCarousel() {
  const theme = useTheme();
  const [activeStep, setActiveStep] = useState(0);
  const maxSteps = images.length;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepChange = (step: number) => {
    setActiveStep(step);
  };

  return (
    <Box
      sx={{
        width: 400,
        maxWidth: "100%",
        flexGrow: 1,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center"
      }}
    >
      <Paper
        square
        elevation={0}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: 50,
          pl: 2,
          bgcolor: "background.default",
        }}
      >
        <Typography>{images[activeStep].label}</Typography>
      </Paper>
      <AutoPlaySwipeableViews
        axis={theme.direction === "rtl" ? "x-reverse" : "x"}
        index={activeStep}
        onChangeIndex={handleStepChange}
        enableMouseEvents
        interval={_FLIP_INTERVAL_SEC}
      >
        {images.map((step, index) => (
          <Box
            key={step.label}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              maxWidth: "100%",
              border: "none",
              outline: "none",
            }}
          >
            {Math.abs(activeStep - index) <= 2 ? (
              <Box
                component="div"
                sx={{
                  height: 255,
                  border: "none",
                  outline: "none",
                  maxWidth: "100%",
                  overflow: "hidden",
                  display: "flex",
                  width: "400px",
                  backgroundImage: `url(${step.imgPath})`,
                  backgroundPosition: step.backgroundPosition,
                  backgroundRepeat: "no-repeat",
                  backgroundSize: step.backgroundSize,
                  "@media (max-width: 600px)": {
                    backgroundPosition:
                      step.backgroundPositionResponsive.length > 0
                        ? step.backgroundPositionResponsive
                        : step.backgroundPosition,
                    backgroundSize:
                      step.backgroundSizeResponsive.length > 0
                        ? step.backgroundSizeResponsive
                        : step.backgroundSize,
                  },
                }}
              />
            ) : null}
          </Box>
        ))}
      </AutoPlaySwipeableViews>
      <Box
      sx={{
        width: "100%",
        "@media (min-width: 600px)": {
          width: "30%",
        },
      }}
      >
        <MobileStepper
          steps={maxSteps}
          variant="progress"
          position="static"
          activeStep={activeStep}
          nextButton={
            <Button
              size="small"
              onClick={handleNext}
              disabled={activeStep === maxSteps - 1}
            >
              {theme.direction === "rtl" ? (
                <KeyboardArrowLeft />
              ) : (
                <KeyboardArrowRight />
              )}
            </Button>
          }
          backButton={
            <Button
              size="small"
              onClick={handleBack}
              disabled={activeStep === 0}
            >
              {theme.direction === "rtl" ? (
                <KeyboardArrowRight />
              ) : (
                <KeyboardArrowLeft />
              )}
            </Button>
          }
        />
      </Box>
    </Box>
  );
}

export default ImageCarousel;
